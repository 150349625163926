import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './ProductList.module.css';
import { useParams } from 'react-router-dom';
import { devLog } from '../../helpers/HelperFunctions';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const ProductList = () => {

  const [products, setProducts] = useState([]);
  const { shopId, categoryId } = useParams();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    devLog('Display products of category: ', categoryId);
    devLog('Display products of shop: ', shopId);

    axios.get(`${BACKEND_URL}/products/${shopId}/${categoryId}`, { withCredentials: true })
      .then(response => {
        console.log('Retrieved Products: ', response.data);
        const productsData = Array.isArray(response.data) ? response.data : [];
        setProducts(productsData);
        devLog('Retrieved products from server: ', productsData);
      })
      .catch(error => {
        devLog('Error fetching products:', error);
        setProducts([]);
      });

  }, [categoryId, BACKEND_URL]);

  return (
    <main>
      <Helmet>
        <title>Online Shop -  Product List</title>
        <meta name="description" content="Mind Nourish online shop product list" />
        <meta name="keywords" content="merchandise" />
      </Helmet>

      <h1 className={styles.title}>Product List</h1>

      <div className={styles.container}>
        {products.map((product) => (

          <Link className={styles.styledLink} to={`/shop/product/${product.name}`} key={product.id}>
            <div className={styles.productContainer}>

              {product.image && <img
                src={product.image.url}
                alt={product.image.alt}
                className={styles.productImage}
              />}

              <h3 className={styles.productName}>{product.name}</h3>
              <p className={styles.productDescription}>{product.description}</p>
              <p className={styles.productPrice}>£{product.price}</p>
            </div>
          </Link>
        ))}
      </div>
    </main>
  );
};

export default ProductList;
