import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CategoryList.module.css';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { devLog } from '../../helpers/HelperFunctions';

const CategoryList = () => {

  const [categories, setCategories] = useState([]);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
  const SHOP_ID = process.env.REACT_APP_SHOP_ID;

  useEffect(() => {
    axios.get(`${BACKEND_URL}/categories`, { withCredentials: true })
      .then(response => {
        console.log('Retrieved Categories: ', response.data);
        const goalsData = Array.isArray(response.data) ? response.data : [];
        setCategories(goalsData);
        devLog('Retrieved user goals from server: ', goalsData);
      })
      .catch(error => {
        devLog('Error fetching goals:', error);
        setCategories([]);
      });

  }, [BACKEND_URL]);

  return (
    <main>
      <Helmet>
        <title>Online Shop -  Categories</title>
        <meta name="description" content="Mind Nourish online shop categories" />
        <meta name="keywords" content="merchandise" />
      </Helmet>

      <h1 className={styles.title}>Categories</h1>

      <p className={styles.introParagraph}>
        <span role="img" aria-label="celebrate">🎉</span> Welcome to our curated collection, where you can explore a variety of unique products tailored to your preferences. Dive into our categories to discover premium apparel <span role="img" aria-label="tshirt">👕</span>,
        exclusive in-game add-ons <span role="img" aria-label="game controller">🎮</span>, and a diverse range of miscellaneous items. Every category promises a blend of quality and innovation, designed especially for you.
        Begin your journey below and find what resonates with your taste!
      </p>

      <div className={styles.container}>
        {categories.map((category) => (
          
          <Link className={styles.styledLink} to={`/shop/products/${SHOP_ID}/${category.id}`} key={category.id} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className={styles.categoryContainer}>
              <h3 className={styles.categoryName}>{category.name}</h3>
              <p className={styles.categoryDescription}>{category.description}</p>

              {category.image && <img
                src={category.image.url}
                alt={category.name}
                className={styles.categoryImage}
              />}

            </div>
          </Link>
        ))}
      </div>
    </main>
  );
};

export default CategoryList;

