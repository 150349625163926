import React from 'react';
import styles from './CollectibleMushrooms.module.css';
import { Helmet } from 'react-helmet';
import UserFeedback from './common/UserFeedback';
import Collectibles from './Collectibles';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { sendTelegramMessage, sendAuditLog } from '../helpers/HelperFunctions';
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_COLLECTIBLES, ENTITY_ID_SIGNUP } from '../helpers/Constants';

function CollectibleMushrooms() {
    const navigate = useNavigate();
    const { user } = useUser();

    const handleSignupClick = () => {
        sendTelegramMessage('Collectibles - "Join Now" button clicked');

        const userID = user ? user.id : null;
        sendAuditLog(userID, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_COLLECTIBLES, ENTITY_ID_SIGNUP, null);

        navigate('/signup');
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Collectible Mushrooms - Mind Nourish</title>
                <meta name="description" content="Discover the intriguing world of collectible mushrooms at Mind Nourish and how they enhance your wellness journey." />
                <meta name="keywords" content="collectibles, collectible mushrooms, Mind Nourish game, wellness journey" />
            </Helmet>

            <section className={styles.section}>
                <h1 className={styles.title}><strong>Collectible Mushrooms:</strong> The Magic Behind Daily Engagement!</h1>
                <p className={styles.description}>In the enchanting world of Mind Nourish, mushrooms are more than just a fun collectible. They represent the essence of continual growth and personal engagement. By nurturing your own mushroom and interacting with our platform, you embark on a unique wellness journey.</p>
                <p className={styles.description}><span role="img" aria-label="mushroom">🍄</span> <strong>Earn Bronze, Silver, and Gold Mushrooms:</strong> Unlock these precious collectibles by logging in daily and collecting them below or engaging with our lifestyle quizzes and activities.</p>
                <p className={styles.description}><span role="img" aria-label="game-controller">🎮</span> <strong>A Game with Purpose:</strong> Control your mushroom, feed it, water it, and protect it from adversities. It's not just a game; it's a reflection of your wellness journey.</p>
                <p className={styles.description}><span role="img" aria-label="shield">🛡</span> <strong>Trade Mushrooms for Power-Ups:</strong> Your collectible mushrooms can be traded for extra lifestyle reports or in-game items, armor, and weapons, enhancing your mushroom's resilience.</p>
                <p className={styles.description}>Join us in this delightful adventure and let these mushrooms be a symbol of your commitment to wellness and personal growth. Let's cultivate and cherish the journey together!</p>

                {!user && <p className={styles.description}>
                    To be able to collect some mushrooms, you need to <span className={styles.highlight}>create an account</span> or <span className={styles.highlight}>log in</span>. Safe, secure, and all about you. <span role="img" aria-label="padlock">🔐</span>
                </p>}

                {!user && <button className={styles.signupButton} onClick={handleSignupClick}>Join Now</button>}

                {user && <Collectibles />}

            </section>

            <UserFeedback pageId="collectibles" />
        </main>
    );
}

export default CollectibleMushrooms;
