import React from 'react';
import { useUser } from '../contexts/UserContext';
import { useBasket } from '../contexts/BasketContext';
import { Link, useNavigate } from 'react-router-dom';
import config from '../config';
import styles from './Nav.module.css';
import LoginComponent from '../components/authentication/LoginComponent';
import EditProfileButton from '../components/user/ProfileDropdown';
import basketImage from '../dynamicAssets/Basket.png';
import { sendTelegramMessage, sendAuditLog } from '../helpers/HelperFunctions';
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_SIGNUP } from '../helpers/Constants';

const Nav = (props) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const { itemCount } = useBasket();

    const handleSignupClick = () => {
        sendTelegramMessage('"Signup" button clicked');
        sendAuditLog(null, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_SIGNUP, null);
        navigate('/signup');
    };

    return (
        <nav className={styles.nav}>
            <Link className={`${styles.navItem} ${styles.navItemBrand}`} to="/">Mind Nourish</Link>

            <div>
                <Link className={styles.navItem} to="/journey">Report</Link>
                <span className={styles.separator}>|</span>
                {config.enableDevFeatures &&
                    <>
                        <Link className={styles.navItem} to="/shop/categories">Shop</Link>
                        <span className={styles.separator}>|</span>
                    </>
                }
                <Link className={styles.navItem} to="/books">Books</Link>
                <span className={styles.separator}>|</span>
                <Link className={styles.navItem} to="/blogs">Blog</Link>
                <span className={styles.separator}>|</span>
                <Link className={styles.navItem} to="/subscriptions">Subscriptions</Link>
                <span className={styles.separator}>|</span>
                <Link className={styles.navItem} to="/about">About</Link>
            </div>

            {user ? (
                <span className={styles.buttonContainer}>
                    <EditProfileButton />
                </span>
            ) : (
                <span className={styles.profileBlock}>
                    <LoginComponent />
                    <span className={styles.buttonContainer}>
                        <button className={styles.signupButton} onClick={handleSignupClick}>
                            Signup
                        </button>
                    </span>
                </span>
            )}
            <div
                className={`${styles.basketIcon} ${config.enableDevFeatures ? '' : styles.hiddenBasket}`}
                onClick={() => navigate('/shop/basket')}
                data-count={itemCount()}
            >
                <img
                    src={basketImage}
                    alt="shopping basket"
                    width={20}
                    height={20}
                    className={styles.styledBasketImage}
                />
                <span className={styles.basketIconCount}>{itemCount()}</span>
            </div>
        </nav>
    )
}

export default Nav
