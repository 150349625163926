import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SiteMap.module.css';
import Helmet from 'react-helmet';

function SiteMap({ user, config }) {
    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish Site Map</title>
                <meta name="description" content="Mind Nourish site map" />
                <meta name="keywords" content="site map" />
            </Helmet>

            <h1 className={styles.title}>Site Map</h1>

            <p>Welcome to the Mind Nourish Sitemap. Here you'll find a comprehensive list of all the pages on our website, making it easy to navigate and discover our content. Explore our resources and find the information you need about health, wellness, and technology.</p>

            <ul className={styles.siteMapList}>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/">Home</Link></li><div className={styles.siteMapItemDescription}>Discover the latest updates and featured content on our homepage.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/about">About Us</Link></li><div className={styles.siteMapItemDescription}>Learn more about Mind Nourish and our mission.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/ai">AI for Wellness</Link></li><div className={styles.siteMapItemDescription}>Details of how we use AI to add to your experience.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/blogs">Blogs</Link></li><div className={styles.siteMapItemDescription}>Read insightful articles on health, lifestyle, fitness, and technology.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/contact">Contact Us</Link></li><div className={styles.siteMapItemDescription}>Get in touch with us for any inquiries, feedback, or support.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/donate">Donations</Link></li><div className={styles.siteMapItemDescription}>If you would like to donate you can find instructions here.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/disclaimer">Disclaimer</Link></li><div className={styles.siteMapItemDescription}>Some important insights about the information we provide.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/faqs">FAQs</Link></li><div className={styles.siteMapItemDescription}>A list of commonly asked questions with answers.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/game-instructions">Game Instructions</Link></li><div className={styles.siteMapItemDescription}>Instructions on how to play the mushroom game.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/journey">Journey</Link></li><div className={styles.siteMapItemDescription}>Describes how you can start your lifestyle journey with us.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/leader-board">Leaderboard</Link></li><div className={styles.siteMapItemDescription}>A list of all the top mushroom game players.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/mushroom">The Mushroom</Link></li><div className={styles.siteMapItemDescription}>Whats all this about mushrooms?</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/newsletter">Newsletter</Link></li><div className={styles.siteMapItemDescription}>Allows you to sign up for our newletter to get regular news and articles.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/subscriptions">Subscriptions</Link></li><div className={styles.siteMapItemDescription}>The different subscription models we have for the lifestyle reports.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/privacy-policy">Privacy Policy</Link></li><div className={styles.siteMapItemDescription}>Our privacy policy.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/report-info">Report Information</Link></li><div className={styles.siteMapItemDescription}>Information about our customised lifestyle report that will be created for you.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/report-instructions">Report Instructions</Link></li><div className={styles.siteMapItemDescription}>Here you can find instructions on how to generate your own lifestyle report.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/sarah">Sarah Intro</Link></li><div className={styles.siteMapItemDescription}>Meet Sarah, your AI friend.</div>
                <li className={styles.siteMapItem}><Link className={styles.siteMapLink} to="/signup">Signup</Link></li><div className={styles.siteMapItemDescription}>This is where you can sign up for an account with us. It's completely free.</div>
            </ul>
        </main>
    );
}

export default SiteMap;
