import React, { useState } from 'react';
import axios from 'axios';
import styles from './SignupComponent.module.css';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { devLog } from '../../helpers/HelperFunctions';
import Helmet from 'react-helmet';

/**
 * Signup Component.
 * 
 * Provides a form that a new user can fill in to create a new account.
 * 
 */
function SignupComponent() {
    const [userInfo, setUserInfo] = useState({
        name: '',
        age: '',
        gender: '-',
        weight: '',
        height: '',
        activity_level: '',
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        age: '',
        email: '',
        password: '',
        activity_level: '',
        weight: '',
        height: ''
    });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    const validateAge = (age) => {
        if (isNaN(age) || age < 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                age: "Please enter a valid age",
            }));
            return false;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            age: '',
        }));
        return true;
    }

    const validateWeight = (weight) => {
        if (isNaN(weight) || weight < 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                weight: "Enter valid weight in KG"
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            weight: ''
        }));
        return true;
    }

    const validateHeight = (height) => {
        if (isNaN(height) || height < 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                height: "Enter valid height in CM"
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            height: ''
        }));
        return true;
    }

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Please enter a valid email address",
            }));
            return false;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: '',
        }));
        return true;
    }

    const validatePassword = (password) => {
        // Regular expression for password validation
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;

        if (!passwordRegex.test(password)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: "Password must be 8+ characters with upper, lower, number and symbol",
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            password: '',
        }));
        return true;
    }

    const validateName = (name) => {
        if (name === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            name: '',
        }));
        return true;
    }

    const validateActivityLevel = (name) => {
        if (name === '' || name === '-') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                activity_level: 'Activity Level is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            activity_level: '',
        }));
        return true;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'name') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateName(value);
        } else if (name === 'email') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateEmail(value);
        } else if (name === 'password') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validatePassword(value);
        } else if (name === 'activity_level') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateActivityLevel(value);
        } else {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }

    const handleSignupClick = () => {
        // Only proceed if there are no validation errors
        if (Object.values(errors).every(x => !x)) {

            // Convert any empty strings to null so they can bind in the backend
            const submissionData = {
                ...userInfo,
                age: userInfo.age === '' ? null : parseInt(userInfo.age, 10),
                weight: userInfo.weight === '' ? null : parseFloat(userInfo.weight),
                height: userInfo.height === '' ? null : parseFloat(userInfo.height)
            };
            devLog(JSON.stringify(submissionData));
            devLog(submissionData);

            axios.post(`${BACKEND_URL}/signup`, submissionData)
                .then(response => {
                    enqueueSnackbar('Profile Created! Please login.', { variant: 'success' });
                    console.log('Signup - Response from Server: ', response.data.id)

                    navigate(-1);
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data.error.toLowerCase().includes('users_email_key'.toLowerCase())) {
                            enqueueSnackbar('Email address already taken', { variant: 'error' });
                        } else {
                            enqueueSnackbar('Error creating user', { variant: 'error' });
                        }
                    } else if (error.request) {
                        enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                    } else {
                        enqueueSnackbar(`Error: ${error.response.status}`, { variant: 'error' });
                    }
                });
        }
    }

    const handleCancelClick = () => {
        navigate('/');
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            handleSignupClick();
        }
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish Signup</title>
                <meta name="description" content="Mind Nourish account creation" />
                <meta name="keywords" content="account creation, account signup, create mind nourish profile" />
            </Helmet>
            <h1 className={styles.title}>Create your account below</h1>
            <h2 className={styles.signupDescription}>And embark on a journey of wellness with us</h2>

            <div className={styles.inputGroup}>
                <div className={styles.styledLabel}>Name <span className={styles.mandatoryMarker}>*</span></div>
                <input className={styles.styledInput}
                    name="name"
                    value={userInfo.name}
                    onChange={handleInputChange}
                    onBlur={() => validateName(userInfo.name)}
                    onKeyUp={handleKeyUp}
                    autoComplete="name"
                    placeholder="Name"
                />
                {errors.name && <div className={styles.validationBlock}>{errors.name}</div>}
            </div>
            <div className={styles.inputGroup}>
                <div className={styles.styledLabel}>Email <span className={styles.mandatoryMarker}>*</span></div>
                <input className={styles.styledInput}
                    name="email"
                    type="text"
                    value={userInfo.email}
                    onChange={handleInputChange}
                    onBlur={() => validateEmail(userInfo.email)}
                    onKeyUp={handleKeyUp}
                    autoComplete="email"
                    placeholder="Email"
                />
                {errors.email && <div className={styles.validationBlock}>{errors.email}</div>}
            </div>
            <div className={styles.inputGroup}>
                <div className={styles.styledLabel}>Password <span className={styles.mandatoryMarker}>*</span></div>
                <input className={styles.styledInput}
                    name="password"
                    type="password"
                    value={userInfo.password}
                    onChange={handleInputChange}
                    onBlur={() => validatePassword(userInfo.password)}
                    onKeyUp={handleKeyUp}
                    placeholder="Password"
                />
                {errors.password && <div className={styles.validationBlock}>{errors.password}</div>}
            </div>
            <div className={styles.inputGroup}>
                <div className={styles.styledLabel}>Age</div>
                <input className={styles.styledInput}
                    name="age"
                    type="text"
                    value={userInfo.age}
                    onChange={handleInputChange}
                    onBlur={() => validateAge(userInfo.age)}
                    onKeyUp={handleKeyUp}
                    placeholder="Age"
                />
                {errors.age && <div className={styles.validationBlock}>{errors.age}</div>}
                <div className={styles.styledLabel}>Weight (kgs)</div>
                <input className={styles.styledInput}
                    name="weight"
                    type="number"
                    step="0.01"
                    value={userInfo.weight}
                    onChange={handleInputChange}
                    onBlur={() => validateWeight(userInfo.weight)}
                    onKeyUp={handleKeyUp}
                    placeholder="Weight in KG"
                />
                {errors.weight && <div className={styles.validationBlock}>{errors.weight}</div>}
                <div className={styles.styledLabel}>Height (cm)</div>
                <input className={styles.styledInput}
                    name="height"
                    type="number"
                    step="0.01"
                    value={userInfo.height}
                    onChange={handleInputChange}
                    onBlur={() => validateHeight(userInfo.height)}
                    onKeyUp={handleKeyUp}
                    placeholder="Height in CM"
                />
                {errors.height && <div className={styles.validationBlock}>{errors.height}</div>}
            </div>
            <div className={styles.inputGroup}>
                <div className={styles.styledLabel}>Gender</div>
                <select className={styles.styledSelect} name="gender" value={userInfo.gender} onChange={handleInputChange}>
                    <option value="-">-</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                    <option value="prefer_not_to_say">Prefer not to say</option>
                </select>

                <div className={styles.styledLabel}>Activity Level <span className={styles.mandatoryMarker}>*</span></div>
                <select className={styles.styledSelect} name="activity_level" value={userInfo.activity_level} onChange={handleInputChange} onBlur={() => validateActivityLevel(userInfo.activity_level)}>
                    <option value="-">-</option>
                    <option value="none">None</option>
                    <option value="lightly_active">Lightly Active</option>
                    <option value="moderately_active">Moderately Active</option>
                    <option value="very_active">Very Active</option>
                    <option value="super_active">Super Active</option>
                </select>
                {errors.activity_level && <div className={styles.validationBlock}>{errors.activity_level}</div>}
            </div>

            <div className={styles.inputGroup}>
                <div className={styles.styledLabel}>Diet Preference</div>
                <select className={styles.styledSelect} name="diet_preference" value={userInfo.diet_preference} onChange={handleInputChange}>
                    <option value="none">None</option>
                    <option value="vegetarian">Vegetarian</option>
                    <option value="vegan">Vegan</option>
                    <option value="pescatarian">Pescatarian</option>
                    <option value="omnivore">Omnivore</option>
                    <option value="paleo">Paleo</option>
                    <option value="keto">Keto</option>
                    <option value="mediterranean">Mediterranean</option>
                    <option value="low_carb">Low-Carb</option>
                    <option value="high_protein">High-Protein</option>
                    <option value="gluten_free">Gluten-Free</option>
                    <option value="halal">Halal</option>
                    <option value="kosher">Kosher</option>
                    <option value="other">Other</option>
                </select>

                <div className={styles.styledLabel}>Dietary Restrictions</div>
                <select className={styles.styledSelect} name="diet_restriction" value={userInfo.diet_restriction} onChange={handleInputChange}>
                    <option value="none">None</option>
                    <option value="lactose_intolerant">Lactose Intolerant</option>
                    <option value="nut_allergy">Nut Allergy</option>
                    <option value="gluten_free">Gluten Free</option>
                    <option value="shellfish_allergy">Shellfish Allergy</option>
                    <option value="soy_allergy">Soy Allergy</option>
                    <option value="egg_allergy">Egg Allergy</option>
                    <option value="fish_allergy">Fish Allergy</option>
                    <option value="wheat_allergy">Wheat Allergy</option>
                    <option value="low_sodium">Low Sodium</option>
                    <option value="diabetic">Diabetic</option>
                    <option value="other">Other</option>
                </select>
            </div>

            <button className={styles.signupButton} onClick={handleSignupClick}>Create Account</button>
            <button className={styles.cancelButton} onClick={handleCancelClick}>Cancel</button>
            <p className={styles.mandatoryNote}><span className={styles.mandatoryMarker}>*</span> indicates mandatory fields</p>

        </main>
    );
}

export default SignupComponent;
