import React, { useState } from 'react';
import axios from 'axios';
import styles from './ContactUs.module.css';
import { useSnackbar } from 'notistack';
import MushroomGoldIcon from './common/MushroomGoldIcon';
import { devLog } from '../helpers/HelperFunctions';
import { Helmet } from 'react-helmet';
import UserFeedback from './common/UserFeedback';

function ContactUs() {
    const { enqueueSnackbar } = useSnackbar();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: '',
    });

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        devLog('Sending to server: ', formData)
        e.preventDefault();
        // Only proceed if there are no validation errors
        if (Object.values(errors).every(x => !x)) {
            axios.post(`${BACKEND_URL}/contact`, formData)
                .then(response => {
                    devLog(response)
                    enqueueSnackbar('Message Sent!', { variant: 'success' });
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                    })
                })
                .catch(error => {
                    devLog(error)
                    if (error.response) {
                        enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                    } else if (error.request) {
                        enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                    } else {
                        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                    }
                });
        }
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Please enter a valid email address.",
            }));
            return false;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: '',
        }));
        return true;
    }

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish: How to Contact Us</title>
                <meta name="description" content="Alows users to contact us and send feedback" />
                <meta name="keywords" content="contact us, connect with us" />
            </Helmet>

            <h1 className={styles.title}>Connect with Us</h1>

            <p className={styles.feedbackText}>
                We&apos;re on a mission to make the world of health and lifestyle more insightful and personalised,
                and your insights are invaluable to us. Share your thoughts, ideas, or suggestions, and help us serve you better.
                As our way of saying thank you, registered users will be gifted a silver mushroom for their invaluable input.
                <span className={styles.mushroomIconWrapper}><MushroomGoldIcon variant="small" /></span>
            </p>


            <form className={styles.form} onSubmit={handleSubmit}>
                <input className={styles.input}
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    autoComplete="name"
                    required
                />

                <input className={styles.input}
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={() => validateEmail(formData.email)}
                    autoComplete="email"
                    required
                />
                {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}

                <textarea className={styles.textarea}
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                />

                <button className={styles.submitButton} type="submit">Send Message</button>
            </form>
            <div className={styles.userFeedback}>
                <UserFeedback pageId={"contact"} />
            </div>
        </main>
    );
}

export default ContactUs;
