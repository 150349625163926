import React from 'react';
import styles from './Books.module.css';
import { Helmet } from 'react-helmet';
import BookImage1 from '../dynamicAssets/MyHealingDiary.jpg';
import BookImage2 from '../dynamicAssets/MyBigFatGriefDiet.jpg';
import BookImage3 from '../dynamicAssets/HealingPuzzleBook.jpg';
import BookImage4 from '../dynamicAssets/WordsOfRenewalPuzzleBook.jpg';
import BookImage5 from '../dynamicAssets/ValentinesPuzzleBook.jpg';
import BookImage6 from '../dynamicAssets/JewelleryPuzzleBook.jpg';
import BookImage7 from '../dynamicAssets/GothicPuzzleBook.jpg';
import BookImage8 from '../dynamicAssets/LingeriePuzzleBook.jpg';

function Books() {
    const books = [
        {
            id: 1,
            image: BookImage1,
            title: "My Healing Diary",
            description: "A guided journal to help you navigate grief day by day.",
            link: "https://amzn.to/4aEWNyX"
        },
        {
            id: 2,
            image: BookImage2,
            title: "My Big Fat Grief Diet",
            description: "A book about how I learned to live a healthier lifestyle whilst dealing with grief.",
            link: "https://amzn.to/4aKksOd"
        },
        {
            id: 3,
            image: BookImage3,
            title: "My Big Fat Healing Puzzle Book",
            description: "A relaxing word search book filled with healing words.",
            link: "https://amzn.to/4aLkJRm"
        },
        {
            id: 4,
            image: BookImage4,
            title: "Words of Renewal Puzzle Book",
            description: "Word searches for healing, wellness, and self-Care.",
            link: "https://amzn.to/3CBkA6e"
        },
        {
            id: 5,
            image: BookImage5,
            title: "Valentines Puzzle Book",
            description: "Celebrate love with fun! .",
            link: "https://amzn.to/4hkkC1x"
        },
        {
            id: 6,
            image: BookImage6,
            title: "Jewellery Puzzle Book",
            description: "A sparkling collection of word searches inspired by gems and designs.",
            link: "https://amzn.to/4hFFO1s"
        },
        {
            id: 7,
            image: BookImage7,
            title: "Gothic Puzzle Book",
            description: "Haunted places, supernatural beings, dark poetry and eerie legends.",
            link: ""
        },
        {
            id: 8,
            image: BookImage8,
            title: "Lingerie Puzzle Book",
            description: "A stylish collection of word searches celebrating the art of intimate fashion.",
            link: "https://amzn.to/4goVPYU"
        }
    ];

    return (
        <main className={styles.booksContainer}>
            <Helmet>
                <title>Books by Peter R Thomas</title>
                <meta name="description" content="Discover my collection of healing and grief books, as well as engaging word search puzzle books." />
                <meta name="keywords" content="grief books, healing books, word search puzzles, Mind Nourish books" />
            </Helmet>
            <h1 className={styles.title}>Books by Peter R Thomas</h1>
            <p className={styles.description}>
                Explore my carefully crafted books designed to help you through grief, healing, and personal growth.
                I also offer fun and relaxing puzzle books to engage your mind.
            </p>

            <div className={styles.booksGrid}>
                {books.map((book) => (
                    <div key={book.id} className={styles.bookCard}>
                        <img className={styles.bookImage} src={book.image} alt={book.title} />
                        <a href={book.link} className={styles.bookLink} target="_blank" rel="noopener noreferrer">Buy Now</a>
                        <h3 className={styles.bookTitle}>{book.title}</h3>
                        <p className={styles.bookDescription}>{book.description}</p>
                    </div>
                ))}
            </div>
        </main>
    );
}

export default Books;
