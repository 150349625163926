import React from 'react';
import VideoWrapper from './media/VideoWrapper';
import styles from './Videos.module.css';

const Videos = () => {
  return (
    <div>
      <h1 className={styles.title}>Mind Nourish Videos</h1>
      <VideoWrapper videoId="o7cCJqya7wc" />
    </div>
  );
};

export default Videos;
