import React from 'react';
import styles from './Footer.module.css';
import FacebookIcon from '../components/common/FacebookIcon';
import InstagramIcon from '../components/common/InstagramIcon';
import YouTubeIcon from '../components/common/YouTubeIcon';
import TikTokIcon from '../components/common/TikTokIcon';
//import ChatBot from '../components/ChatBot';
//import config from '../config';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
    const location = useLocation(); 

    return (
        <>
        {location.pathname !== '/lifefiend' && <div className={styles.footer}>
            <div className={styles.linkGroup}>
                <Link to="/report-instructions" className={styles.styledLink}>Your Lifestyle Report</Link>
                <Link to="/sarah" className={styles.styledLink}>Say Hello to Sarah!</Link>
                <Link to="/subscriptions" className={styles.styledLink}>Subscriptions</Link>
                <Link to="/game-instructions" className={styles.styledLink}>Game Instructions</Link>
                <Link to="/mushroom" className={styles.styledLink}>The Mushroom</Link>
                <Link to="https://buymeacoffee.com/mindnourish" className={styles.styledLink} target="_blank" rel="noreferrer">Buy Me a Coffee</Link>
            </div>
            <div className={styles.linkGroup}>
                <Link to="/donate" className={styles.styledLink}>Donations</Link>
                <Link to="/disclaimer" className={styles.styledLink}>Disclaimer</Link>
                <Link to="/faqs" className={styles.styledLink}>FAQs</Link>
                <Link to="/about" className={styles.styledLink}>About Us</Link>
                <Link to="/newsletter" className={styles.styledLink}>Newsletter</Link>
            </div>
            <div className={styles.linkGroup}>
                <Link to="/collectibles" className={styles.styledLink}>Collectibles</Link>
                <Link to="/ai" className={styles.styledLink}>AI and Wellness: A New Era</Link>
                <Link to="/privacy-policy" className={styles.styledLink}>Privacy Policy</Link>
                <Link to="/site-map" className={styles.styledLink}>Site Map</Link>
                <Link to="/contact" className={styles.styledLink}>Contact Us</Link>
            </div>

            <div className={styles.socialMediaGroup}>
                {/*config.enableDevFeatures &&
                    <span className={styles.chatBotLink}>
                        <ChatBot />
                    </span>
                */}
                <Link to="https://www.facebook.com/profile.php?id=61552108076732" className={styles.socialMediaLink} target="_blank" rel="noreferrer">
                    <FacebookIcon />
                </Link>
                <Link to="https://www.instagram.com/MindNourishTech" className={styles.socialMediaLink} target="_blank" rel="noreferrer">
                    <InstagramIcon />
                </Link>
                <Link to="https://www.youtube.com/@MindNourishTech" className={styles.socialMediaLink} target="_blank" rel="noreferrer">
                    <YouTubeIcon />
                </Link>
                <Link to="https://www.tiktok.com/@lifefiend" className={styles.socialMediaLink} target="_blank" rel="noreferrer">
                    <TikTokIcon />
                </Link>
            </div>
            <p className={styles.copyrightText}>
                © {new Date().getFullYear()} Mind Nourish. All Rights Reserved.
            </p>
        </div>}
        </>
    );
};

export default Footer;
