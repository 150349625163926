import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './ReportInfo.module.css';
import UserFeedback from './common/UserFeedback';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

const ReportInfo = () => {

    const navigate = useNavigate();

    const handleGetReportClick = () => {
        ReactGA.event({
            name: 'clicked_get_your_lifestyle_report',
            params: {
                content_type: 'button'
            }
        });

        navigate('/journey');
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Lifestyle Report Information</title>
                <meta name="description" content="Describes how to the lifestyle report will help you" />
                <meta name="keywords" content="lifestyle report information, health report" />
            </Helmet>
            <h1 className={styles.title}>The Magic Behind Your Personalised Lifestyle Report at Mind Nourish <span role="img" aria-label="pink flower">🌸</span></h1>

            <section className={styles.introduction}>
                <h2>Why Mind Nourish?</h2>
                <p className={styles.paragraph}>
                    At Mind Nourish, we understand that health and well-being are personal and unique to each individual. Our mission is to empower you with a lifestyle report tailored to your unique needs, habits, and preferences.
                </p>

                <h2>The Power of AI in Personalisation</h2>
                <p className={styles.paragraph}>
                    Our platform uses advanced Artificial Intelligence (AI) to analyse your responses. This technology enables us to dive deep into the data, understand patterns, and provide you with actionable insights and advice that feels like it's coming from a trusted friend who knows you inside out.
                </p>
            </section>

            {/* ... Your current content on how to get the report ... */}

            <section className={styles.advantages}>
                <h2>Benefits of Your Personalised Lifestyle Report</h2>
                <p className={styles.paragraph}>
                    When you receive your lifestyle report from Mind Nourish, you're getting:
                </p>
                <ul>
                    <li>Insights powered by cutting-edge AI, tailored for you.</li>
                    <li>Recommendations that understand and adapt to your lifestyle.</li>
                    <li>Access to a community of like-minded individuals on the path to better health.</li>
                    <li>A holistic view of your well-being, touching on physical, mental, and emotional aspects.</li>
                </ul>
            </section>

            <section className={styles.callToAction}>
                <h2>Ready to Nourish Your Mind?</h2>
                <p className={styles.paragraph}>
                    Take the first step towards a more mindful and nourished life. Let our AI-powered platform guide you in painting a vivid picture of your health and well-being.
                </p>
                <button className={styles.startButton} onClick={handleGetReportClick}>Get Your Lifestyle Report <span role="img" aria-label="star">🌟</span></button>
            </section>

            <UserFeedback pageId="report-info" />
        </main>
    );
}

export default ReportInfo;
