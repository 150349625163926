import React from 'react';
import styles from './AIEmbrace.module.css';
import UserFeedback from './common/UserFeedback';
import { Helmet } from 'react-helmet';

function AIEmbrace() {
    return (
        <main className={styles.container}>
            <Helmet>
                <title>Embrace Artificial Intelligence</title>
                <meta name="description" content="Discover how AI can help you with health and lifestyle" />
                <meta name="keywords" content="ai, embrace ai, embrace artificial intelligence, lifestyle" />
            </Helmet>

            <h1 className={styles.title}>Embracing the Power of AI: Innovate or Stagnate</h1>

            <p className={styles.paragraph}>
                Throughout history, humans have utilised tools and technologies to enhance their abilities. From the first stone tools to modern supercomputers, every tool was invented to expand human potential. AI is no different - it&apos;s another step in this journey of innovation.
            </p>

            <h2 className={styles.sectionTitle}>1. Personalisation</h2>

            <p className={styles.paragraph}>
                One of AI&apos;s primary strengths is its ability to process vast amounts of data quickly. With Mind Nourish, AI helps tailor wellness strategies to each user by understanding their habits, preferences, and needs on a deeply individual level. It&apos;s not about replacing human interaction; it&apos;s about enhancing the personal touch.
            </p>

            <h2 className={styles.sectionTitle}>2. Accessibility</h2>

            <p className={styles.paragraph}>
                Before AI, accessing expert wellness advice required booking appointments and waiting in line. With AI, expert-level insights are available to you anytime, anywhere, democratising access to high-quality information and guidance.
            </p>

            <h2 className={styles.sectionTitle}>3. Continual Learning</h2>

            <p className={styles.paragraph}>
                Unlike static systems, AI learns and grows with you. The more you interact with Mind Nourish, the better it gets at providing you with actionable and relevant wellness advice, ensuring that the guidance remains relevant to your changing lifestyle and needs.
            </p>

            <h2 className={styles.sectionTitle}>4. Efficiency</h2>

            <p className={styles.paragraph}>
                Time is precious. AI helps automate routine tasks and processes, enabling users to focus on applying the insights and enjoying the benefits. Instead of sifting through pages of generic information, get straight to what matters to you.
            </p>

            <h2 className={styles.sectionTitle}>5. Bridging the Gap</h2>

            <p className={styles.paragraph}>
                AI doesn&apos;t replace human experts; it amplifies their reach. With the support of AI, life and nutrition coaches can assist more people, ensuring that their expertise benefits many more than traditional methods would allow.
            </p>

            <p className={styles.paragraph}>
                The future is collaborative. AI is not about machines taking over - it&apos;s about humans and machines working together to create a brighter, healthier future. Embracing AI is not just about keeping up with the times; it&apos;s about unlocking new possibilities in wellness, personal growth, and holistic living.
            </p>
            <UserFeedback pageId="ai" />
        </main>
    );
}

export default AIEmbrace;
