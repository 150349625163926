import React from 'react';
import styles from './AboutUs.module.css';
import { Helmet } from 'react-helmet';
import UserFeedback from './common/UserFeedback';

function AboutUs() {
    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish: About Us</title>
                <meta name="description" content="A description of who Mind Nourish are and what we do" />
                <meta name="keywords" content="about us" />
            </Helmet>

            <section className={styles.section}>
                <h1 className={styles.title}><strong>Mind Nourish:</strong> A Journey of Healing and Growth.</h1>
                <p className={styles.description}>After facing a profound personal loss, I found myself searching for ways to balance grief, healing, and daily life. That journey led me to create Mind Nourish, a blend of technology and heartfelt support aimed at helping others find strength and wellness through even the darkest times. Here, it's not just about advice; it&apos;s about sharing my path and giving you tools that feel like a natural part of your life.</p>
                <p className={styles.description}><span role="img" aria-label="plant">🌱</span> <strong>Personalised Insights Just for You:</strong> Using advanced AI alongside my experiences, I bring insights that grow with you. Whether you&apos;re looking to feel stronger in body, nurture your mental health, or create small positive changes each day, I&apos;m here to provide advice that&apos;s personally crafted for your unique journey.</p>
                <p className={styles.description}><span role="img" aria-label="handshake">🤝</span> <strong>Real Human Connection:</strong> Behind each tool and tip is a community that understands the depth of human experience. I created this space to be a source of genuine warmth and understanding, a place where we can heal and support each other in a way that&apos;s both human and heartfelt.</p>
                <p className={styles.description}><span role="img" aria-label="lightbulb">💡</span> <strong>Guidance for Real Transformation:</strong> From small shifts in daily habits to deeper lifestyle changes, my aim is to offer advice that can truly transform your wellness. Together, we&apos;ll explore ideas that help create a brighter, healthier tomorrow, one rooted in compassion and resilience.</p>
                <p className={styles.description}><span role="img" aria-label="book">📖</span> <strong>Explore <em>My Healing Diary</em>:</strong> As part of my journey, I&apos;ve written <em>My Healing Diary</em>, a personal grief journal designed to help others on their healing path. Learn more about My Healing Diary here and discover how it can be a companion through your own moments of reflection and growth.</p>
                <p className={styles.description}>Thank you for joining me on this journey. Mind Nourish is here to be a guiding light on your path to healing and wellness, with a hand to hold along the way. Let's grow together.</p>
            </section>

            <div className={styles.feedback}>
                <UserFeedback pageId="about" />
            </div>
        </main>
    );
}

export default AboutUs;
