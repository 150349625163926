import React, { useState } from 'react';
import styles from './FAQs.module.css';
import UserFeedback from './common/UserFeedback';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function FAQs() {
    const [openFAQ, setOpenFAQ] = useState(null);

    const handleQuestionClick = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(index);
        }
    };

    const faqs = [
        {
            question: 'What is Mind Nourish?',
            answer: 'Mind Nourish is a platform that helps individuals delve deeper into their lifestyles, preferences, and aspirations to offer tailored recommendations.'
        },
        {
            question: 'How do I get started?',
            answer: (
                <>
                    To get started, create an account or log in, then navigate to the <Link className={styles.link} to="/">home section</Link> and click on &quot;Create Your Lifestyle Report&quot; to begin your personalised experience. For more details, check out our <Link className={styles.link} to="/report-instructions">Report Instructions</Link> page.
                </>
            )
        },
        {
            question: 'How much does a lifestyle report cost?',
            answer: (
                <>
                    Great news! The basic Mind Nourish report is <strong>absolutely free</strong>! With our Free Tier, you can get one report a week and receive our monthly newsletter. It&apos;s our way to help you embark on your wellness journey without any hurdles.
                    <br />
                    <br />
                    As for our subscription model, we&apos;re currently developing exciting new tiers that will offer even more comprehensive reports, among other amazing benefits. Our Monthly Subscription tier is launching soon, so stay tuned for all the fabulous features it will bring!
                </>
            )
        },
        {
            question: 'What should I do when I get my lifestyle report?',
            answer: (
                <>
                    Once you receive your personalised Mind Nourish report, take some time to read through the insights, tips, and advice carefully. Each section of the report is crafted to give you a unique understanding of different aspects of your lifestyle and wellness.
                    <br />
                    <br />
                    🌟 <strong>Understand Your Score:</strong> The report provides you with a health/lifestyle score. This score offers a quick snapshot of where you stand, helping you identify areas for improvement.
                    <br />
                    <br />
                    💬 <strong>Connect with the Community:</strong> Use our community forums to discuss your report with other members. Sharing experiences and tips can provide additional insights.
                    <br />
                    <br />
                    📆 <strong>Take Action:</strong> Your report will include tailored advice and action items. Consider implementing these steps into your daily routine to start making progress.
                    <br />
                    <br />
                    💚 <strong>Seek Further Guidance:</strong> While our AI-driven recommendations are a great starting point, you&apos;re also welcome to seek out additional professional advice tailored to your specific needs.
                    <br />
                    <br />
                    🔄 <strong>Continuous Improvement:</strong> Remember, wellness is a journey! Continue to use Mind Nourish to monitor your progress and adapt your lifestyle choices for continuous improvement.
                </>
            )
        },
        {
            question: 'How often should I visit Mind Nourish for a new report?',
            answer: (
                <>
                    We recommend that you visit Mind Nourish at least once a week to generate a new health and lifestyle report.
                    <br />
                    <br />
                    🗓 <strong>Weekly Check-in:</strong> With our Free Tier, you&apos;re eligible for one report per week. Use this opportunity to assess any changes in your lifestyle or to measure the effects of the actions you&apos;ve taken based on our previous advice.
                    <br />
                    <br />
                    📈 <strong>Track Your Progress:</strong> Consistency is key! By generating reports weekly, you&apos;ll be able to closely monitor your progress and adapt your lifestyle choices more efficiently.
                    <br />
                    <br />
                    🌟 <strong>Unlock More Benefits:</strong> In the future, our subscription tiers will allow for more frequent reporting along with additional features to further enhance your wellness journey.
                    <br />
                    <br />
                    💌 <strong>Stay Updated:</strong> Don&apos;t forget to check out our monthly newsletter for valuable tips, articles, and updates that can complement the advice in your weekly reports.
                    <br />
                    <br />
                    🎮 <strong>Mushroom&apos;s Adventure Game:</strong> While you wait for your next report, feel free to play our Mushroom&apos;s Adventure Game for some fun and educational entertainment!
                    <br />
                    <br />
                    📚 <strong>Engaging Blogs:</strong> Our regularly updated blogs provide additional insights and tips to guide you on your wellness journey.
                </>
            )
        },
        {
            question: 'Do you have any product recommendations?',
            answer: 'Absolutely! We regularly research and test out products that can enhance your wellness journey. Look out for our recommended products within your reports or in our blog posts. These are often affiliate links, which means we might earn a commission if you choose to make a purchase.'
        },
        {
            question: 'What are the benefits of subscribing?',
            answer: 'Our subscription service offers numerous benefits, including more comprehensive reports, priority customer support, exclusive discounts on affiliate products, and early access to new features. Stay tuned for our upcoming Monthly Subscription tier!'
        },
        {
            question: 'What do you do with my personal data?',
            answer: (
                <>
                    Here at <strong>Mind Nourish</strong>, we take your privacy seriously. We collect only the data essential for providing our services, such as your email, name, age, and answers to our lifestyle questions. This information is securely stored and never sold or shared with third parties. We use your data to tailor our recommendations and provide a personalised experience.
                    <br />
                    <br />
                    Your data is processed and stored in compliance with the General Data Protection Regulation (GDPR). For more information on how your personal data is handled, you can read our <Link className={styles.link} to="/privacy-policy">Privacy Policy</Link>.
                </>
            )
        },
        {
            question: 'Is my data secure?',
            answer: (
                <>
                    Yes, your data is secure and confidential. We use it primarily to improve your experience and tailor our recommendations. For more details, you can read our <Link className={styles.link} to="/privacy-policy">Privacy Policy</Link>.
                </>
            )
        },
        {
            question: 'Can I donate to the site?',
            answer: (
                <>
                    Absolutely, and thank you for considering supporting <strong>Mind Nourish</strong>! We greatly appreciate any donations as they help us continue to improve our platform and services for users like you. If you're interested in donating, please visit our <Link className={styles.link} to="/donate">Donation Page</Link>.
                    <br />
                    <br />
                    Please note that while donations help fund our operations, they do not influence the recommendations or content on our platform. Our primary goal is to provide unbiased, helpful content for our users. For more details about how donations are used, feel free to contact our team.
                </>
            )
        },
        {
            question: 'How do I stay updated with Mind Nourish?',
            answer: 'To stay updated with all the latest news, articles, and updates from Mind Nourish, simply sign up for our newsletter. You\'ll be the first to know about new features, promotional offers, and premium content!'
        },
        {
            question: 'What is the Mushroom Survival Game?',
            answer: 'The Mushroom Survival Game is an interactive and educational game on our platform where you nurture and protect a virtual mushroom, learning about its health benefits along the way. It\'s a fun way to learn more about the benefits of mushrooms while playing a game.'
        },
        {
            question: 'How can I benefit from mushrooms in real life?',
            answer: 'Mushrooms are not only a culinary delight but are also packed with essential nutrients like fiber, protein, and antioxidants. They can play a role in preventing diseases such as Alzheimer’s, heart disease, and diabetes. Incorporating them into your diet can help boost immunity, reduce heart disease risk, and even aid in weight loss.'
        },
        {
            question: 'How does the game educate players about mushrooms?',
            answer: 'As you progress through the Mushroom Survival Game, you will unlock interesting facts and information about mushrooms, their health benefits, and their role in our diet. This educational component ensures players not only enjoy the game but also gain valuable knowledge about these fascinating fungi.'
        },
        {
            question: 'Do I need to pay to play the Mushroom Survival Game?',
            answer: 'No, the Mushroom Survival Game is free to play on our platform. We believe in blending fun with learning and want everyone to have access to this educational experience.'
        },
        {
            question: 'I\'m having technical issues. How can I get help?',
            answer: (
                <>
                    If you&apos;re facing any technical difficulties, please navigate to our <Link className={styles.link} to="/contact">Contact</Link> page and submit a query. Our support team will get back to you promptly.
                </>
            )
        }
    ];


    return (
        <>
            <main className={styles.container}>
                <Helmet>
                    <title>Mind Nourish: Frequently Asked Questions</title>
                    <meta name="description" content="List of questions and answers for the website" />
                    <meta name="keywords" content="faq, faqs, frequently asked questions" />
                </Helmet>

                <h1 className={styles.title}>Frequently Asked Questions</h1>

                {faqs.map((faq, index) => (
                    <div className={styles.questionContainer} key={index} onClick={() => handleQuestionClick(index)}>
                        <h3 className={styles.question}>{faq.question}</h3>
                        <p className={`${styles.answer} ${openFAQ === index ? styles.answerOpen : ''}`}>
                            {faq.answer}
                        </p>
                    </div>
                ))}

            </main>

            <div className={styles.feedback}>
                <UserFeedback pageId="faqs" />
            </div>
        </>
    );
}

export default FAQs;
