import React, { useState } from 'react';
import styles from './PuzzleBookSolutionSignup.module.css';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import config from '../config';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { devLog } from '../helpers/HelperFunctions'

function PuzzleBookSolutionSignup() {
    const [email, setEmail] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const puzzleBook = searchParams.get('book') || 'default';

    const handleSubmit = async () => {
        devLog('Sending: ', puzzleBook)
        devLog('To: ', email)
        await axios.post(`${BACKEND_URL}/solution`, { email: email, book: puzzleBook, tag: 'Puzzle Book Solution' }, { withCredentials: true })
            .then(response => {
                localStorage.setItem(config.solutionSubmitted, 'true');
                enqueueSnackbar('Check your email for the solution PDF!', { variant: 'success' });
                setEmail('');
            })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Get Your Puzzle Solutions</title>
                <meta name="description" content="Enter your email to receive the puzzle book solutions." />
                <meta name="keywords" content="word search, puzzle book solutions, downloadable answers" />
            </Helmet>

            <h1 className={styles.title}><span role="img" aria-label="puzzle">🧩</span> Get Your Puzzle Book Solutions <span role="img" aria-label="check">✅</span></h1>
            
            <p className={styles.paragraph}>
                Thank you for purchasing my puzzle book! To access the complete solution guide, simply enter your email below.
                I'll send you a PDF with all the answers for <strong>{puzzleBook}</strong> so you can check your progress anytime.
            </p>
            
            <div>
                <input className={styles.input}
                    type="email"
                    id="emailSolution"
                    name="emailSolution"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                    autoComplete="email" 
                />
                <button className={styles.button} onClick={handleSubmit}>
                    Get Solutions
                </button>
            </div>
        </main>
    );
}

export default PuzzleBookSolutionSignup;
